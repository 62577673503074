import React, { useState } from "react";
import Containers from "../../components/Containers";
import MineCraftUser from "./container/MinecraftUser/MineCraftUser";
import WebSiteUser from "./container/WebSiteUsers/WebSiteUser";
import Staff from "./container/Staff/Staff";
const Users = () => {
  const tabs = [
    {
      permission: "MODERATEUR",
      name: "Utilisateurs Minecraft",
      content: <MineCraftUser />,
    },
    {
      permission: "ADMINISTRATEUR",
      name: "Utilisateurs WebSite",
      content: <WebSiteUser />,
    },
    {
      permission: "STAFF",
      name: "Ban list",
      content: <h2>information</h2>,
    },
    {
      permission: "ADMINISTRATEUR",
      name: "Staff",
      content: <Staff />,
    },
  ];

  return <Containers tabs={tabs} title="Utilisateurs" />;
};
export default Users;
