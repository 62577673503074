import React from "react";
import Containers from "../../components/Containers";

import InstagramImageEditor from "../../components/ImageOverlay/ImageOverlay";
import Gallery from "./container/Gallery/Gallery";

import ArticleTest from "./container/Article/f";
import ArticleEditor from "./container/Article/ArticleEditor";
import ArticleManagement from "./container/Article/ArticleManagement";
import ArticleMedia from "./container/Article/ArticleMedia";

const WebSite = () => {
  const tabs = [
    {
      name: "Galerie",
      content: <Gallery />,
      permission: "STAFF",
    },
    {
      name: "éditeur d'image",
      content: <InstagramImageEditor />,
      permission: "STAFF",
    },
    {
      name: "Éditeur d'article",
      content: <ArticleEditor />,
      permission: "STAFF",
    },
    {
      name: "gestion articles",
      content: <ArticleManagement />,
      permission: "STAFF",
    },

    // {
    //   name: "test",
    //   content: <ArticleMedia />,
    //   permission: "STAFF",
    // },
  ];
  return <Containers tabs={tabs} title="webSite" />;
};

export default WebSite;
