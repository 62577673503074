import React, { useEffect, useState } from "react";
import GalleryTable from "./GalleryTable";
import ImageForm from "./ImageForm";
import ConfirmationModal from "../../../../Pages/dashBoard/containers/ConfirmationModal"; // Import the existing modal
import {
  getAllGallery,
  setStatusImage,
  deleteImage,
} from "../../../../services/services";

const Gallery = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  const handleAddImage = (image) => {
    const { data: submittedData } = image;
    const updatedData = [...data, submittedData];
    setData(updatedData);
  };

  const handleUpdateStatus = async (image) => {
    const { _id, validated } = image;

    try {
      const { response } = await setStatusImage(_id, {
        validated,
      });

      if (response.status === 200) {
        const updatedData = data.map((item) =>
          item._id === _id ? { ...item, validated: !validated } : item,
        );
        setData(updatedData);
      } else {
        console.error("Échec de la mise à jour du statut");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error);
    }
  };

  const handleDelete = async () => {
    if (!imageToDelete) return;

    try {
      const { response } = await deleteImage(imageToDelete._id);

      if (response.status === 200) {
        const updatedData = data.filter(
          (item) => item._id !== imageToDelete._id,
        );
        setData(updatedData);
        setIsModalOpen(false);
      } else {
        console.error("Échec de la suppression de l'image");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'image :", error);
    }
  };

  const openDeleteModal = (image) => {
    setImageToDelete(image);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setImageToDelete(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const { data: fetchedData, response } = await getAllGallery();
      if (response.status === 200) {
        setData(fetchedData);
      }
    })();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeOptions = [20, 50, 80, 100];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  return (
    <div className="container mx-auto p-4">
      <div className="space-y-8">
        <div className="p-4 bg-gray-100 rounded">
          <h1 className="text-xl font-bold mb-4">Ajouter une Image</h1>
          <ImageForm onSubmit={handleAddImage} />
        </div>
        <div className="p-4 bg-gray-100 rounded">
          <h1 className="text-xl font-bold mb-4">Galerie</h1>
          <GalleryTable
            data={data}
            pageSize={pageSize}
            currentPage={currentPage}
            onUpdateStatus={handleUpdateStatus}
            onDelete={openDeleteModal} // Pass the function to open the modal
          />
        </div>
      </div>

      {/* Confirmation Modal */}
      {imageToDelete && (
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDelete}
          name={imageToDelete}
        />
      )}
    </div>
  );
};

export default Gallery;
