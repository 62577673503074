import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/0362e60c-548e-457c-aaab-d4e7934b9340.png";
import Button from "../Button";
import { FaUserLargeSlash } from "react-icons/fa6";
import { getRoutingPermission, logout } from "../../services/services";
import Notiflix from "notiflix";
import { useDispatch } from "react-redux";
import { setRoute } from "../../store/RoutingStore";

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Routes, setRoutes] = useState([]);
  const handleLogOut = async (e) => {
    try {
      const response = await logout();

      if (response.response.data.code === "USER_LOGOUT") {
        localStorage.clear();
        Notiflix.Notify.success("Vous êtes déconnecté !");
        navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      const { response, data } = await getRoutingPermission();
      if (response.status === 200) {
        setRoutes([...data.data.links]);
        dispatch(setRoute([...data.data.links]));
      }
    })();
  }, []);

  return (
    <header className="flex flex-col md:flex-row shadowCustom items-center justify-between px-3 py-1">
      <img src={Logo} alt="logo Novus" className="w-14 h-14 mb-2 md:mb-0" />

      <nav className="flex flex-col md:flex-row gap-4 md:gap-8 w-full md:w-[50%] items-center justify-center">
        {Routes.map(({ title, link }, index) =>
          NavLinkCustom(title, link, index),
        )}
      </nav>

      <Button
        variant="cancel"
        type="button"
        withIcons={true}
        iconsPosition="left"
        icon={<FaUserLargeSlash />}
        className="bg-[#DC3540] text-white text-[17px] font-bold center mt-2 md:mt-0"
        onClick={handleLogOut}
      >
        Déconnexion
      </Button>
    </header>
  );
};

function NavLinkCustom(title, link, index) {
  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        [
          isActive ? "underline" : "",
          "font-exo capitalize text-lg text-NO_DarkGray font-bold flex gap-1 items-center",
        ].join(" ")
      }
      key={index}
    >
      {title}
    </NavLink>
  );
}

export default Navigation;
