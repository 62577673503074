import React from "react";
import {
  Button,
  Cell,
  Collection,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  useTableOptions,
} from "react-aria-components";
import { FaLock, FaLockOpen } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
// import RowOptionMenuTrigger from "./LightBox";
import { getValidationIcon, showDate } from "../../../../utils/utils";
import LightBox from "./LightBox";

const GalleryTable = ({
  data,
  currentPage,
  pageSize,
  onUpdateStatus,
  onDelete,
}) => {
  let rows = [...data];

  function MyTableHeader({ columns }) {
    let { allowsDragging } = useTableOptions();

    return (
      <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md">
        {allowsDragging && <Column />}
        <Collection items={columns}>
          {(column) => (
            <Column
              className="p-1 text-white"
              key={column.id}
              isRowHeader={column.isRowHeader}
            >
              {column.name}
            </Column>
          )}
        </Collection>
        <Column />
      </TableHeader>
    );
  }

  function MyRow({ id, columns, item }) {
    return (
      <Row id={id} item={item} className="border-[#3f3f46] border-2 text-black">
        {columns.map((col, index) => {
          let cellValue = checkColID(col.id, item);

          return (
            <Cell key={index} className={" h-[40px] p-2"}>
              {cellValue}
            </Cell>
          );
        })}

        <Cell className="center gap-3 p-2">
          <LightBox data={item} />
          <FaTrashAlt
            className="text-red-700 mb-1 cursor-pointer hover:text-red-500 transition-text duration-500"
            onClick={() => onDelete(item)}
          />
        </Cell>
      </Row>
    );
  }

  function checkColID(col, item) {
    switch (col) {
      case "validated":
        return (
          <Button className="text-center" onPress={() => onUpdateStatus(item)}>
            {getValidationIcon(item[col])}
          </Button>
        );
      case "author":
        return (
          <span className="flex gap-2 item-center justify-center">
            {item[col].map((el) => (
              <p key={el}>{el}</p>
            ))}
          </span>
        );
      case "tags":
        return (
          <span className="flex gap-2 items-center justify-center">
            {item[col].map((el) => (
              <p key={el}>{el}</p>
            ))}
          </span>
        );

      case "createdAt":
        return showDate(item[col]);
      default:
        return item[col];
    }
  }

  const columns = [
    { name: "Nom", id: "name" },
    { name: "Description", id: "description", isRowHeader: true },
    { name: "Auteur", id: "author" },
    { name: "Tags", id: "tags" },
    { name: "Créé le", id: "createdAt" },
    { name: "Validé", id: "validated" },
  ];

  return (
    <Table
      id="test"
      aria-label="doc"
      disabledBehavior="selection"
      className="w-full max-w-[100%] max-h-[73vh]"
    >
      <MyTableHeader columns={columns} />
      <TableBody
        className="text-center bg-gray text-black"
        renderEmptyState={() => "Aucun résultat trouvé."}
      >
        {rows
          .slice(currentPage * pageSize - pageSize, currentPage * pageSize)
          .map((item, index) => (
            <MyRow key={index} id={index} columns={columns} item={item} />
          ))}
      </TableBody>
    </Table>
  );
};

export default GalleryTable;
