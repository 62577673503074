import React, { useState, useEffect } from "react";
import {
  Button,
  Cell,
  Collection,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  useTableOptions,
} from "react-aria-components";
import { FaEye } from "react-icons/fa";
import {
  customCompare,
  getValidationIcon,
  showDate,
} from "../../../../utils/utils";
import ArticleModalPreview from "./ArticleModalPreview";
import { publishArticle } from "../../../../services/services";

const ArticleTable = (props) => {
  const columns = [
    { name: "ID", id: "_id", isRowHeader: true },
    { name: "TITRE", id: "TITLE", isRowHeader: true },
    { name: "AUTEUR", id: "AUTHOR" },
    { name: "STATUS", id: "STATUS" },
    { name: "CRÉÉ LE", id: "createdAt" },
  ];
  const [rows, setRows] = useState([...props.data].sort(customCompare));

  useEffect(() => {
    setRows([...props.data].sort(customCompare));
  }, [props.data]);

  const handleUpdateStatus = async (image) => {
    const { _id, STATUS } = image;

    try {
      const { response } = await publishArticle(_id, {
        STATUS,
      });

      if (response.status === 200) {
        const updatedData = rows.map((item) =>
          item._id === _id ? { ...item, STATUS: !STATUS } : item,
        );
        setRows(updatedData);
      } else {
        console.error("Échec de la mise à jour du statut");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error);
    }
  };

  function checkColID(col, item) {
    switch (col) {
      case "createdAt":
        return showDate(item[col]);
      case "STATUS":
        return (
          <Button
            className="text-center"
            onPress={() => {
              handleUpdateStatus(item);
            }}
          >
            {getValidationIcon(item[col])}
          </Button>
        );
      default:
        return item[col];
    }
  }

  function MyTableHeader({ columns }) {
    let { allowsDragging } = useTableOptions();

    return (
      <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md sticky top-0 z-10">
        {allowsDragging && <Column />}
        <Collection items={columns}>
          {(column) => (
            <Column
              className="p-1 text-white"
              key={column.id}
              isRowHeader={column.isRowHeader}
            >
              {column.name}
            </Column>
          )}
        </Collection>
        <Column />
      </TableHeader>
    );
  }

  function MyRow({ id, columns, item }) {
    return (
      <Row
        id={id}
        item={item}
        className="border-[#3f3f46] border-2 text-black bg-white"
      >
        {columns.map((col, index) => {
          let cellValue = checkColID(col.id, item);

          return (
            <Cell key={index} className="h-[40px] p-2 text-center">
              {cellValue}
            </Cell>
          );
        })}

        <Cell className="center h-[40px] p-2">
          <ArticleModalPreview data={item} />
        </Cell>
      </Row>
    );
  }

  return (
    <Table
      aria-label="Article Table"
      disabledBehavior="selection"
      className="w-full max-w-[100%] max-h-[73vh] rounded-md overflow-hidden"
    >
      <MyTableHeader columns={columns} />
      <TableBody
        className="text-center text-black max-h-[60vh] overflow-y-auto"
        renderEmptyState={() => (
          <div className="p-4 text-center text-gray-400">
            Aucun résultat trouvé.
          </div>
        )}
      >
        {rows
          .slice(
            props.currentPage * props.pageSize - props.pageSize,
            props.currentPage * props.pageSize,
          )
          .map((item, index) => (
            <MyRow key={index} id={index} columns={columns} item={item} />
          ))}
      </TableBody>
    </Table>
  );
};

export default ArticleTable;
