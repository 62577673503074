import React, { useEffect } from "react";
import { Outlet } from "react-router";
import Navigation from "./Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FRONTEND_ERRORS from "../../utils/ErrorsCode";

const ConnectedLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navigation />

      <main className="flex-1 p-8 bg-NO_DarkGray text-white font-exo">
        {<Outlet />}
      </main>
    </div>
  );
};
export default ConnectedLayout;
