import React from "react";

const BannerBlock = ({ content, onChange }) => (
  <div>
    <input
      type="text"
      className="w-full p-2 mb-2 border rounded focus:border-blue-500 outline-none"
      value={content}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Lien de l'image"
    />
    {content && (
      <img
        src={content}
        alt="Aperçu"
        className="max-w-full  rounded shadow object-cover"
      />
    )}
  </div>
);

export default BannerBlock;
