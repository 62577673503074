import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Notiflix from "notiflix";

import logo from "../../assets/0362e60c-548e-457c-aaab-d4e7934b9340.png";
import Button from "../../components/Button";
import { login } from "../../services/services";
import { useNavigate } from "react-router-dom";
import { isAuthenticate } from "../../services/authContext";
const LoginPage = () => {
  document.title = "Novus odyssey Login";
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      USERNAME: "",
      PASSWORD: "",
    },
    validationSchema: Yup.object({
      USERNAME: Yup.string().required("pseudo requis").min(2),
      PASSWORD: Yup.string()
        .min(4, "Mot de passe trop court")
        .required("Mot de passe requis"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setHasSubmitted(true);

      Notiflix.Loading.circle("Chargement...");

      try {
        const response = await login(values);
        console.log(response);
        localStorage.setItem("UUID", response.data.data.UUID);
        localStorage.setItem("USERNAME", response.data.data.USERNAME);
        Notiflix.Notify.success("Connexion réussie!");
      } catch (e) {
        console.error(e);
        Notiflix.Notify.failure("Échec de la connexion.");
        navigate("/error", {
          state: {
            code: "NO_TOKEN_PROVIDED",
            status: 401,
            message: "Échec de la connexion.",
          },
        });
      } finally {
        setIsSubmitting(false);
        Notiflix.Loading.remove();
        navigate("/dashboard/");
      }
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <div className="font-exo flex items-center justify-center min-h-screen bg-NO_DarkGray ">
      <div className="bg-white p-8 rounded-lg shadow-lg shadow-black/20 w-full max-w-md shadowCustom">
        <div className="center flex-col mb-6">
          <img src={logo} alt="Logo" className="h-52 w-52" />
          <h1 className="text-NO_DarkGray font-semibold text-2xl text-center">
            Novus odyssey Backoffice
            <br className="max-sm:hidden" />
            connexion
          </h1>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="space-y-6 center flex-col w-full "
        >
          <div>
            <label
              htmlFor="USERNAME"
              className="block text-sm font-medium text-gray-700"
            >
              username
            </label>
            <input
              id="USERNAME"
              type="USERNAME"
              {...formik.getFieldProps("USERNAME")}
              onBlur={formik.handleBlur}
              className="mt-1 block w-full px-3 py-2 bg-white border shadow-inner rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {formik.touched.USERNAME && formik.errors.USERNAME ? (
              <div className="text-red-500 text-xs mt-1">
                {formik.errors.USERNAME}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="PASSWORD"
              className="block text-sm font-medium text-gray-700"
            >
              Mot de passe
            </label>
            <div className="relative mt-1">
              <input
                id="PASSWORD"
                type={showPassword ? "text" : "PASSWORD"}
                {...formik.getFieldProps("PASSWORD")}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  if (hasSubmitted) {
                    formik.validateField("PASSWORD");
                  }
                }}
                className="block w-full px-3 py-2 bg-white border shadow-inner rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-3 flex items-center text-black"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {hasSubmitted && formik.errors.PASSWORD ? (
              <div className="text-red-500 text-xs mt-1">
                {formik.errors.PASSWORD}
              </div>
            ) : null}
          </div>
          <div className="">
            <Button type="submit" size="large" disabled={isSubmitting}>
              Se connecter
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
