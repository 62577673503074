import React from "react";

import Containers from "../../components/Containers";

const Minecraft = () => {
  const tabs = [
    {
      name: "leaderboard",
      content: <h2>Gestion</h2>,
    },
    {
      name: "panneau information",
      content: <h2>information</h2>,
    },
  ];
  return <Containers tabs={tabs} title="Minecraft" />;
};

export default Minecraft;
